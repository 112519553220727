import * as MUI from '@mui/material';

const theme = MUI.createTheme({
    palette: {
        background: {
          default: '#D8DDDE',
          paper: '#ffffff',
        },
        primary: {
          main: '#33506b',
        },
        mode: 'light',
        text: {
          primary: 'rgba(55,55,55,0.87)',
        },
        secondary: {
          main: '#9D5C63',
        },
      },
  });


export default theme;
