import * as React from 'react';
import * as MUI from '@mui/material';
import * as convert_units from 'convert-units';

import Icon from '@mui/material/Icon';
import ClearIcon from '@mui/icons-material/Clear';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

import { RecipeContext } from '../../Recipe';
import { type_grouped_fermentables } from '../tabs/FermentablesTab.js';
import * as BeerData from '../../BeerData'
import * as Recipe from '../../Recipe'
import NumberField from '../NumberField';

export default function FermentablesTable(props) {
  const { recipe_data, setRecipeData } = React.useContext(RecipeContext);
  const [ fermentable_total_proportion, setFermentableTotalProportion ] = React.useState(0);

  // //TODO: memoize this?
  // const 
  function updateFermentableListEntry(nextRow) {
    setRecipeData({
      ...recipe_data, fermentables: (recipe_data.fermentables.map(f => {
        if (f.fermentable_data.name === nextRow.fermentable_data.name) { //TODO: is name a suitable UID for this? if not generate a UID and use that instead.
          return nextRow;
        } else {
          return f;
        }
      }))
    });

    setFermentableTotalProportion(recipe_data.fermentables.reduce((total, { proportion }) => total + Number(proportion), 0));
  }

  function addFermentableRow(new_fermentable) {
    setRecipeData({
      ...recipe_data,
      fermentables: [...recipe_data.fermentables, new_fermentable]
    });
  }

  function deleteFermentableRow(fermentableToDelete) {
    setRecipeData({
      ...recipe_data,
      fermentables: recipe_data.fermentables.filter((fermentable) => fermentable !== fermentableToDelete)
    });
  }

  return (
    <MUI.TableContainer component={MUI.Paper} {...props}>
      {/* TODO: proper title component? */}
      <MUI.Box m={2}>
        <MUI.Typography variant="h6"> Fermentables </MUI.Typography>
      </MUI.Box>

      <MUI.Table sx={{ minWidth: 650 }} aria-label="fermentable table">
        <MUI.TableHead>
          <MUI.TableRow>
            <MUI.TableCell align="center" colSpan={2}>Name</MUI.TableCell> 
            <MUI.TableCell align="center">Proportion in Recipe (%)</MUI.TableCell>
            <MUI.TableCell align="center">Sugar required from fermentable (GU's)</MUI.TableCell>
            <MUI.TableCell align="center">Sugar Per Gallon of the Fermentable (GU's)</MUI.TableCell>
            <MUI.TableCell align="center">Extraction Efficiency</MUI.TableCell>
            <MUI.TableCell align="center">Required Quantity</MUI.TableCell>
          </MUI.TableRow>
        </MUI.TableHead>
        <MUI.TableBody>
          {
            //allows React to automagically add a key to children, avoiding warnings and improving render efficency
            React.Children.toArray(
              recipe_data.fermentables.map((fermentable_row) => (
                <FermentableRow
                  fermentable_row={fermentable_row}
                  updateFermentableListEntry={updateFermentableListEntry}
                  deleteFermentableRow={deleteFermentableRow} />
              ))
            )}

          {/* Empty colour cell */}
          <MUI.TableRow>
          <MUI.TableCell/>

            <MUI.TableCell>
              <AddFermentableButton addFermentableRow={addFermentableRow} />
            </MUI.TableCell>

            <MUI.TableCell>
              <MUI.Stack>
                <MUI.Typography variant="h8" align='center'>Total Proportion:</MUI.Typography>
                <MUI.Typography variant="h5" align='center' color={fermentable_total_proportion === 100 ? "green" : "red"}>{fermentable_total_proportion}%</MUI.Typography>
              </MUI.Stack>
            </MUI.TableCell>

            {/* Empty columns so the divide goes all the way across below this row. */}
            <MUI.TableCell colSpan={4} />
          </MUI.TableRow>
        </MUI.TableBody>
      </MUI.Table>
    </MUI.TableContainer>
  );
}

function FermentableRow({fermentable_row, updateFermentableListEntry, deleteFermentableRow}) {
  const { recipe_data } = React.useContext(RecipeContext);
  const [isHovered, setIsHovered] = React.useState(false);

  const batch_required_sugar = recipe_data.batch_required_sugar();

  //Something has changed; we need to recalculate everything in refermentable.
  function RecalculateFermentable() {
    fermentable_row.required_sugar = batch_required_sugar * (fermentable_row.proportion/100);
    fermentable_row.quantity = fermentable_row.required_sugar / (fermentable_row.fermentable_data.sugar_per_gallon * (fermentable_row.extract_efficiency/100));
    
    //http://www.highwoodsbrewing.com/srm-color.php
    //Calculate Malt Colour Units 
    //MCU = (Grain Weight lbs. * Grain Color deg L) / Volume gal
    let mcu = (fermentable_row.quantity * fermentable_row.fermentable_data.lovibond)/ recipe_data.batch_volume;

    // SRM Color = 1.4922 * (MCU ^ 0.6859)
    fermentable_row.imparted_SRM = (1.4922 * Math.pow(mcu, 0.6859)).toFixed(0)
  }


  // eslint-disable-next-line
  React.useEffect(() => {
    RecalculateFermentable();
    updateFermentableListEntry(fermentable_row);
    // eslint-disable-next-line
  }, [fermentable_row, batch_required_sugar]);

  return (
    <MUI.TableRow
      key={fermentable_row.fermentable_data.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}

      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >

      {/* SRM indicator */}
      <MUI.TableCell >
        <SquareRoundedIcon htmlColor={BeerData.SRM_to_hex[fermentable_row.fermentable_data.potential_SRM]} /> 
      </MUI.TableCell>

      {/* Fermentable Name */}
      <MUI.TableCell align="left">
          <MUI.OutlinedInput
            id={fermentable_row.fermentable_data.name + "_row"}
            value={fermentable_row.fermentable_data.name}
           />
        </MUI.TableCell>

      {/* Proportion of Fermentable in brew */}
      <MUI.TableCell align="left">
      {<NumberField 
        id={fermentable_row.fermentable_data.name + "_proportion"}
        endAdornment={<MUI.InputAdornment position="end">%</MUI.InputAdornment>}
        data={fermentable_row.proportion}
        setData={data => {
            updateFermentableListEntry({
              ...fermentable_row,
              proportion: (Math.max(Math.min(data, 100), 0).toFixed(2))
            });
          }}
      />}
      </MUI.TableCell>

      {/* GU required for fermentable */}
      <MUI.TableCell align="left">{<MUI.TextField
        id={"gu_required_row"}
        value={Number(fermentable_row.required_sugar.toFixed(2))}
        color="success"
        focused />} </MUI.TableCell>

      {/* Sugar (GU) of fermentable per gal */}
      <MUI.TableCell align="left">{<MUI.OutlinedInput
        id={fermentable_row.fermentable_data.sugar_per_gallon + "_row"}
        value={Number(fermentable_row.fermentable_data.sugar_per_gallon)}
        disabled />} </MUI.TableCell>

      {/* Extraction Efficiency */}
      <MUI.TableCell align="left">{
        //TODO: we need to replace these percentage inputs to something that updates after we hit enter/tab/click off.
        //This means we can have an unformatted text entry the user can freely mess with without the weird sig fig rounding stuff
        <NumberField 
          id={fermentable_row.extract_efficiency + "_row"}
          endAdornment={<MUI.InputAdornment position="end">%</MUI.InputAdornment>}
          data={fermentable_row.extract_efficiency}
          setData={data => {
              updateFermentableListEntry({
                ...fermentable_row,
                extract_efficiency: (Math.max(Math.min(data, 100), 0).toFixed(2))
              });
            }}
        />
        } </MUI.TableCell>

      {/* Required Quantity */}
      <MUI.TableCell align="left">{<MUI.TextField
        id={fermentable_row.result + "_row"}
        value={(recipe_data.metric ? convert_units(fermentable_row.quantity).from("lb").to("g") : fermentable_row.quantity).toFixed(2)}
        color="success"
        focused
        InputProps={{
          endAdornment: <MUI.InputAdornment position="end">{recipe_data.metric ? "Grams" : "Lbs"}</MUI.InputAdornment>,
        }} />} </MUI.TableCell>

      <MUI.TableCell align="left"> {<MUI.IconButton
        id={fermentable_row.result + "_row"}
        onClick={() => {
          deleteFermentableRow(fermentable_row);
        }}
      >
        {/* Show delete icon if hovered, otherwise show a blank icon to save the space */}
        {isHovered && <ClearIcon />}
        {!isHovered && <Icon />}
      </MUI.IconButton>} </MUI.TableCell>
    </MUI.TableRow>
  );
}

function AddFermentableButton({ addFermentableRow }) {
  const { recipe_data } = React.useContext(RecipeContext);


  function clickFermentable(selected_fermentable_name) {
    if (selected_fermentable_name !== "") {
      var selected_fermentable = BeerData.fermentables.filter(fermentable => {
        return fermentable.name === selected_fermentable_name;
      });

      addFermentableRow(Recipe.createRecipeFermentable(recipe_data, selected_fermentable[0], 0, 100));
    }
  }

  return (
    <MUI.FormControl sx={{ m: 1, minWidth: 200 }}>
      <MUI.InputLabel>Add Fermentable</MUI.InputLabel>
      <MUI.Select
        native defaultValue=""
        id="add-fermentable-select"
        label="Add Fermentable"
        onChange={event => { clickFermentable(event.target.value); }}>
        <option aria-label="None" value="" />

        {/* List sugars by type */Object.keys(type_grouped_fermentables).map((key, index) => (
          <optgroup key={index} label={key}> {
            //allows React to automagically add a key to children, avoiding warnings and improving render efficency
            React.Children.toArray(
              type_grouped_fermentables[key].map((name) => {
                return <option value={name}>{name}</option>;
              })
            )} </optgroup>
        ))}
      </MUI.Select>
    </MUI.FormControl>
  );
}
//Needed due to MUI select with grouping being poop
//https://mui.com/material-ui/react-select/
AddFermentableButton.muiSkipListHighlight = true;
