import * as React from 'react';
import * as MUI from '@mui/material';
import Icon from '@mui/material/Icon';
import ClearIcon from '@mui/icons-material/Clear';

import { RecipeContext } from '../../Recipe';
import { type_grouped_hops } from '../tabs/HopsTab';
import * as Recipe from '../../Recipe'
import * as BeerData from '../../BeerData'

export default function HopTable(props) {
  const { recipe_data, setRecipeData } = React.useContext(RecipeContext);

  function updateHopListEntry(nextRow) {
    setRecipeData({
      ...recipe_data, hops: (recipe_data.hops.map(f => {
        if (f.hop_data.name === nextRow.hop_data.name) { //TODO: is name a suitable UID for this? if not generate a UID and use that instead.
          return nextRow;
        } else {
          return f;
        }
      }))
    });
  }

  function addHopRow(new_hop) {
    setRecipeData({
      ...recipe_data,
      hops: [...recipe_data.hops, new_hop]
    });
  }

  function deleteHopRow(hopToDelete) {
    setRecipeData({
      ...recipe_data,
      hops: recipe_data.hops.filter((hop) => hop !== hopToDelete)
    });
  }

  return (
    <MUI.TableContainer component={MUI.Paper} {...props}>
      {/* TODO: proper title component? */}
      <MUI.Box m={2}>
        <MUI.Typography variant="h6"> Hops </MUI.Typography>
      </MUI.Box>

      <MUI.Table sx={{ minWidth: 650 }} aria-label="hop table">
        <MUI.TableHead>
          <MUI.TableRow>
            <MUI.TableCell>Hop</MUI.TableCell>
            <MUI.TableCell align="left">Oz Per Gallon</MUI.TableCell>
            <MUI.TableCell align="left">Total Oz</MUI.TableCell>
            <MUI.TableCell align="left">Alpha Acid Content (%)</MUI.TableCell>
            <MUI.TableCell align="left">Hop Addition Time</MUI.TableCell>
            <MUI.TableCell align="left">IBU's (Tinseth)</MUI.TableCell>
          </MUI.TableRow>
        </MUI.TableHead>
        <MUI.TableBody>
          {
            //allows React to automagically add a key to children, avoiding warnings and improving render efficency
            React.Children.toArray(
              recipe_data.hops.map((hop_row) => (
                <HopRow
                  hop_row={hop_row}
                  updateHopListEntry={updateHopListEntry}
                  deleteHopRow={deleteHopRow}
                  batch_volume={recipe_data.batch_volume}
                />
              ))
            )}

          <MUI.TableRow>
            <MUI.TableCell>
              <AddHopButton addHopRow={addHopRow} />
            </MUI.TableCell>

            {/* Empty columns so the divide goes all the way across below this row. */}
            <MUI.TableCell colSpan={4} />
          </MUI.TableRow>
        </MUI.TableBody>
      </MUI.Table>
    </MUI.TableContainer>
  );
}

function HopRow({hop_row, updateHopListEntry, deleteHopRow, batch_volume}) {
  const { recipe_data } = React.useContext(RecipeContext);

  //Something has changed; we need to recalculate everything in hop bill.
  function RecalculateHop() {
    //https://realbeer.com/hops/research.html
    //Most of these magic numbers have been found expermentally from the above author.
    //TODO: add some knobs to let the user adjust this based on measurments for their particular system?
    //The Bigness factor accounts for reduced utilization due to higher wort gravities. Use an average gravity value for the entire boil to account for changes in the wort volume.
    //Bigness factor = 1.65 * 0.000125^(wort gravity - 1)
    let bigness_factor = 1.65 * Math.pow(0.000125, (recipe_data.original_gravity/1000) - 1)
    //The Boil Time factor accounts for the change in utilization due to boil time:
    //Boil Time factor = 1 - e^(-0.04 * time in mins) / 4.15
    let boil_time_factor = (1 - Math.pow(Math.E, (-0.04 * hop_row.addition_time))) / 4.15

    hop_row.utilisation = bigness_factor * boil_time_factor;

    //https://blog.homebrewing.org/how-to-calculate-ibus/
    //IBUs = Utilisation% * (ALPHA * W_OZ/Gal * 74.89)
    hop_row.IBUs = (hop_row.utilisation) * ((hop_row.hop_data.alpha_acid) * hop_row.quantity_per_gal * 74.89);
    }

  //This is just the one hop in this row - the hops in the hop panel is the 'master' list.
  const [isHovered, setIsHovered] = React.useState(false);

  // eslint-disable-next-line
  React.useEffect(() => {
    RecalculateHop();
    updateHopListEntry(hop_row);
    // eslint-disable-next-line
  }, [hop_row]);

  return (
    <MUI.TableRow
      key={hop_row.hop_data.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}

      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >

      {/* Hop Name */}
      <MUI.TableCell component="th" scope="row">
        {hop_row.hop_data.name}
      </MUI.TableCell>

      {/*Hop Quantity (Oz) per Gal of brew */}
      <MUI.TableCell align="left">{<MUI.OutlinedInput
        id={hop_row.hop_data.name + "_quantity"}
        type="number"
        value={hop_row.quantity_per_gal}
        onChange={event => {
          updateHopListEntry({
            ...hop_row,
            quantity_per_gal: (event.target.value)
          });
        }} />} </MUI.TableCell>

      {/* GU required for hop */}
      <MUI.TableCell align="left">{<MUI.TextField
        id={"hop_total_required_row"}
        value={hop_row.quantity_per_gal * batch_volume}
        color="success"
        focused />} </MUI.TableCell>

      {/* Alpha acid percentage  */}
      <MUI.TableCell align="left">{<MUI.OutlinedInput
        id={hop_row.hop_data.alpha_acid + "_row"}
        value={hop_row.hop_data.alpha_acid}
        InputProps={{
          endAdornment: <MUI.InputAdornment position="end">%</MUI.InputAdornment>,
        }}
        disabled
      />} </MUI.TableCell>

      {/* Hop Addition Time */}
      <MUI.TableCell align="left">{
        <MUI.OutlinedInput
          id={hop_row.addition_time + "_row"}
          type="number"
          //Format the value to remove proceeding 0's
          value={hop_row.addition_time}
          //TODO: replace this so it doesn't sit after the number buttons.
          endAdornment={<MUI.InputAdornment position="end">minutes</MUI.InputAdornment>}
          onChange={event => {
            updateHopListEntry({
              ...hop_row,
              addition_time: (event.target.value)
            });
          }} />} </MUI.TableCell>

      {/* Required Quantity */}
      <MUI.TableCell align="left">{<MUI.TextField
        id={hop_row.IBUs + "_row"}
        value={hop_row.IBUs.toFixed(2)}
        color="success"
        focused
      />} </MUI.TableCell>

      <MUI.TableCell align="left"> {<MUI.IconButton
        id={hop_row.result + "_row"}
        onClick={() => {
          deleteHopRow(hop_row);
        }}
      >
        {/* Show delete icon if hovered, otherwise show a blank icon to save the space */}
        {isHovered && <ClearIcon />}
        {!isHovered && <Icon />}
      </MUI.IconButton>} </MUI.TableCell>
    </MUI.TableRow>
  );
}

function AddHopButton({ addHopRow }) {

  function clickHop(selected_hop_name) {
    if (selected_hop_name !== "") {
      var selected_hop = BeerData.hops.filter(hop => {
        return hop.name === selected_hop_name;
      });

      addHopRow(Recipe.createRecipeHop(selected_hop[0], 0, 0));
    }
  }

  return (
    <MUI.FormControl sx={{ m: 1, minWidth: 200 }}>
      <MUI.InputLabel>Add Hop</MUI.InputLabel>
      <MUI.Select
        native defaultValue=""
        id="add-hop-select"
        label="Add Hop"
        onChange={event => { clickHop(event.target.value); }}>
        <option aria-label="None" value="" />

        {/* List sugars by type */Object.keys(type_grouped_hops).map((key, index) => (
          <optgroup key={index} label={key}> {
            //Allows React to automagically add a key to children, avoiding warnings and improving render efficency
            React.Children.toArray(
              type_grouped_hops[key].map((name) => {
                return <option value={name}>{name}</option>;
              })
            )} </optgroup>
        ))}
      </MUI.Select>
    </MUI.FormControl>
  );
}
//Needed due to MUI select with grouping being poop
//https://mui.com/material-ui/react-select/
AddHopButton.muiSkipListHighlight = true;
