import * as React from 'react';
import * as MUI from '@mui/material';

import * as RecipeIO from '../RecipeIO'

export default function GeneralRecipeDetails(props) {
    return (
        <MUI.Box
            {...props}
            component={MUI.Paper}
            sx={{
                minwidth: 300
            }}
        >
            {/* TODO: proper title component? */}
            <MUI.Box m={2}>
                <MUI.Typography variant="h6"> General Recipe Details </MUI.Typography>
            </MUI.Box>

            <MUI.Stack spacing={2} mx={2} my={4}>

                
                <RecipeIO.QuantityField/>

                <RecipeIO.OriginalGravityField/>

                <RecipeIO.FinalGravityField/>

                <RecipeIO.BatchABV/>

                <RecipeIO.BatchRequiredSugarPerGallon/>

                <RecipeIO.BatchRequiredSugar/>
            </MUI.Stack>
        </MUI.Box>
    );
}