import * as React from 'react';
import {DirectionalLabelSlider} from '../DirectionalLabelSlider'
import * as MUI from '@mui/material';

import { RecipeContext } from '../../Recipe'
import * as RecipeIO from '../RecipeIO'

export default function GravitySlider(props) {
    const { recipe_data, setRecipeData } = React.useContext(RecipeContext);

    const labels = ["FG", "OG"];

    const marks = [
        { value: 0, label: "1000" },
        { value: 140, label: "1140" }
    ];

    const max = 1140, min = 1000;

    return (
        <MUI.Stack 
            component={MUI.Paper}
            width={630}
        >
            {/* TODO: proper title component? */}
            <MUI.Box m={2}>
                <MUI.Typography variant="h6"> Gravities and ABV </MUI.Typography>
            </MUI.Box>

            <DirectionalLabelSlider
                //Margin to allow space for labels
                sx={{ mb: 4, mt: 2, ml: 2, maxWidth: 600}}
                max={max}
                min={min}
                valueLabelFormat={(value, index) => { 
                    return labels[index] + ": " + value;
                }}
                value={[recipe_data.original_gravity, recipe_data.final_gravity]}
                valueLabelDisplay="on"
                color='secondary'
                //TODO: probably just replace this with an "alternating" string that gets handled in the slider prop
                valueLabelPosition={(index) => {
                    if (index === 0)  return "bottom"
                    else return "top"
                }}
                marks={marks}
                onChange={event => {
                    setRecipeData({ ...recipe_data, final_gravity: event.target.value[0], original_gravity: event.target.value[1] });
                }}
            />

            <MUI.Stack direction={"row"} spacing={2} margin={2}>
                <RecipeIO.OriginalGravityField/>

                <RecipeIO.FinalGravityField/>
            </MUI.Stack>

            <RecipeIO.BatchABV
                sx={{ m: 2}}

                
            />

        
        </MUI.Stack>
    );
}





