import * as React from 'react';
import * as MUI from '@mui/material';
import theme from './theme.js';

import TabBar from './components/forms/TabBar';
import FermentablesTab from './components/tabs/FermentablesTab';
import GeneralTab from './components/tabs/GeneralTab'
import HopsTab from './components/tabs/HopsTab'

import * as Recipe from './Recipe.js'
import {tabs} from './components/forms/TabBar';


export default function BrewApp() {
  const [recipe_data, setRecipeData] = React.useState(Recipe.LoadRecipe());
  const [current_tab, setTab] = React.useState(0);

  function handleTabChange(event) {
    setTab(tabs.findIndex(tab => {
      return tab.name === event.currentTarget.value;
    }));
  };
  
  return (
    <Recipe.RecipeContext.Provider value={{recipe_data, setRecipeData}}>
      <MUI.ThemeProvider theme={theme}>

        {/* Use default background colour in theme */}
        <MUI.CssBaseline /> 

        <TabBar handleTabChange={handleTabChange} current_tab={current_tab}/>

        <div>
            <GeneralTab value={current_tab} index={0}></GeneralTab>
            <FermentablesTab value={current_tab} index={1}/>
            <HopsTab value={current_tab} index={2}/>
        </div>

      </MUI.ThemeProvider>
    </Recipe.RecipeContext.Provider>
  );
}