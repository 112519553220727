import * as React from 'react';
import * as MUI from '@mui/material';

export default function NumberField({data, setData, helperText, FormHelperTextProps, ...props}) {

    //This is used when the data is being changed - this value is committed back to the 
    const [number_field_value, setNumberFieldValue ] = React.useState(0);

    //If data gets updated elsewhere, set the display value to the new value of data to keep them in sync.
    React.useEffect(() => {setNumberFieldValue(Number(data).toFixed(2))}, [data])

    // const id = MUI.useId(idOverride);
    // const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

    return (
        <MUI.Stack>
            {/* //This is set up so that the value is only parsed and sent back to the recipe when the box is clicked away from
            //This allows the user to not have the their input changed by the parse as they are written - this is very annoying. */}
            <MUI.OutlinedInput
                {...props}
                color='secondary'
                value={number_field_value}

                //When the field is clicked, set the temp data store to the current value of the variable.
                onFocus={event => {
                    setNumberFieldValue(Number(data).toFixed(2))
                    //Select all the field contents on click to make it easier to replace text.
                    event.target.select();
                }}

                //Update the temp data store
                onChange={event => {
                    setNumberFieldValue(event.target.value)
                }}
                
                //Attempt to parse user input when the user clicks off the field
                onBlur={event => {
                    //Constrain proportion to between 0 and 100
                    //Only allow it to two decimal places when expressed as a percentage, i.e xxx.xx%
                    let parsed_number = Number(number_field_value);

                    //Check input is a number. If not, discard the user changes and show the original pre-edit number.
                    if (isNaN(parsed_number)) {
                        setNumberFieldValue(data);
                        //TODO: pop up a "input not a number" error.
                    } else {
                        setData(event.target.value);
                    }
                }}
            /> 

            {helperText && (
                <MUI.FormHelperText 
                    // id={helperTextId} 
                    {...FormHelperTextProps}
                    variant='filled'
                    >
                {helperText}
                </MUI.FormHelperText>
            )}
        </MUI.Stack>

    );
}
