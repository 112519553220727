import * as React from 'react';
import * as MUI from '@mui/material';

import * as RecipeIO from '../RecipeIO'

export default function RecipeHopDetails(props) {
    return (
        <MUI.Box
            {...props}
            component={MUI.Paper}
            sx={{
                minwidth: 300
            }}
        >
            {/* TODO: proper title component? */}
            <MUI.Box m={2}>
                <MUI.Typography variant="h6" m={1}> Hop Related Details </MUI.Typography>
            </MUI.Box>

            <MUI.Stack spacing={2} mx={2} my={4}>
                <RecipeIO.QuantityField/>
                <RecipeIO.BatchTotalIBUsField/>
                <RecipeIO.OriginalGravityField/>
                <RecipeIO.BatchBUGURatioField/>
            </MUI.Stack>
        </MUI.Box>
    );
}