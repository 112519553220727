function createFermentable(name, country, type, lovibond, sugar_per_gallon) {
  let fermentable = {name, country, type, lovibond, sugar_per_gallon};

  //The colour of the malt
  //Depending on the amount of fermentable added and the batch size, the resultant beer will take on some proportion of this colour.
  fermentable.potential_SRM = (1.3546 * lovibond - 0.76).toFixed(0);
  return fermentable;
}

function createHop(name, alpha_acid, beta_acid, country, aroma, purpose) {
  let hop = {name, country, purpose, aroma, alpha_acid, beta_acid};
  return hop;
}

//TODO: temp, pull this from a DB (and let users add to it)
export const fermentables = [
    createFermentable("Acid Malt", "Germany", "Grain", 3, 27),
    createFermentable("Amber Dry Extract", "US", "Dry Extract", 13, 44),
    createFermentable("Amber Liquid Extract", "US", "Extract", 13, 36),
    createFermentable("Amber Malt", "United Kingdom", "Grain", 22, 35),
    createFermentable("Aromatic Malt", "Belgium", "Grain", 26, 36),
    createFermentable("Barley, Flaked", "US", "Grain", 2, 32),
    createFermentable("Barley, Raw", "US", "Grain", 2, 28),
    createFermentable("Barley, Torrefied", "US", "Grain", 2, 36),
    createFermentable("Biscuit Malt", "Belgium", "Grain", 23, 36),
    createFermentable("Black (Patent) Malt", "US", "Grain", 500, 25),
    createFermentable("Black Barley (Stout)", "US", "Grain", 500, 25),
    createFermentable("Brown Malt", "United Kingdom", "Grain", 65, 32),
    createFermentable("Brown Sugar, Dark", "US", "Sugar", 50, 46),
    createFermentable("Brown Sugar, Light", "US", "Sugar", 8, 46),  
    createFermentable("Brumalt", "Germany", "Grain", 23, 33),
    createFermentable("Candi Sugar, Amber", "Belgium", "Sugar", 75, 36),
    createFermentable("Candi Sugar, Clear", "Belgium", "Sugar", 1, 36),
    createFermentable("Candi Sugar, Dark", "Belgium", "Sugar", 275, 36),
    createFermentable("Cane (Beet) Sugar", "US", "Sugar", 0, 46),
    createFermentable("Cara-Pils/Dextrine", "US", "Grain", 2, 33),
    createFermentable("Caraamber", "US", "Grain", 30, 35),
    createFermentable("Carafoam", "US", "Grain", 2, 33),
    createFermentable("Caramel/Crystal Malt - 10L", "US", "Grain", 10, 35),
    createFermentable("Caramel/Crystal Malt - 20L", "US", "Grain", 20, 35),
    createFermentable("Caramel/Crystal Malt - 30L", "US", "Grain", 30, 35),
    createFermentable("Caramel/Crystal Malt - 40L", "US", "Grain", 40, 34),
    createFermentable("Caramel/Crystal Malt - 60L", "US", "Grain", 60, 34),
    createFermentable("Caramel/Crystal Malt - 80L", "US", "Grain", 80, 34),
    createFermentable("Caramel/Crystal Malt -120L", "US", "Grain", 120, 33),
    createFermentable("Caramunich Malt", "Belgium", "Grain", 56, 33),
    createFermentable("Carared", "US", "Grain", 20, 35),
    createFermentable("Caravienne Malt", "Belgium", "Grain", 22, 34),
    createFermentable("Chocolate Malt", "US", "Grain", 350, 28),
    createFermentable("Chocolate Malt", "United Kingdom", "Grain", 450, 34),
    createFermentable("Corn Sugar (Dextrose)", "US", "Sugar", 0, 46),
    createFermentable("Corn Syrup", "US", "Sugar", 1, 36),
    createFermentable("Corn, Flaked", "US", "Grain", 1, 37),
    createFermentable("Dark Dry Extract", "US", "Dry Extract", 18, 44),
    createFermentable("Dark Liquid Extract", "US", "Extract", 18, 36),
    createFermentable("Dememera Sugar", "United Kingdom", "Sugar", 2, 46),
    createFermentable("Extra Light Dry Extract", "US", "Dry Extract", 3, 44),
    createFermentable("Grits", "US", "Adjunct", 1, 37),
    createFermentable("Honey", "US", "Sugar", 1, 35),
    createFermentable("Invert Sugar", "United Kingdom", "Sugar", 0, 46),
    createFermentable("Light Dry Extract", "US", "Dry Extract", 8, 44),
    createFermentable("Maple Syrup", "US", "Sugar", 35, 30),
    createFermentable("Melanoiden Malt", "Germany", "Grain", 20, 37),
    createFermentable("Mild Malt", "United Kingdom", "Grain", 4, 37),
    createFermentable("Milk Sugar (Lactose)", "US", "Sugar", 0, 35),
    createFermentable("Molasses", "US", "Sugar", 80, 36),
    createFermentable("Munich Malt", "Germany", "Grain", 9, 37),
    createFermentable("Munich Malt - 10L", "US", "Grain", 10, 35),
    createFermentable("Munich Malt - 20L", "US", "Grain", 20, 35),
    createFermentable("Oats, Flaked", "US", "Grain", 1, 37),
    createFermentable("Oats, Malted", "US", "Grain", 1, 37),
    createFermentable("Pale Liquid Extract", "US", "Extract", 8, 36),
    createFermentable("Pale Malt (2 Row) Bel", "Belgium", "Grain", 3, 37),
    createFermentable("Pale Malt (2 Row) UK", "United Kingdom", "Grain", 3, 36),
    createFermentable("Pale Malt (2 Row) US", "US", "Grain", 2, 36),
    createFermentable("Pale Malt (6 Row) US", "US", "Grain", 2, 35),
    createFermentable("Peat Smoked Malt", "United Kingdom", "Grain", 3, 34),
    createFermentable("Pilsner (2 Row) Bel", "Belgium", "Grain", 2, 36),
    createFermentable("Pilsner (2 Row) Ger", "Germany", "Grain", 2, 37),
    createFermentable("Pilsner (2 Row) UK", "United Kingdom", "Grain", 1, 36),
    createFermentable("Pilsner Liquid Extract", "US", "Extract", 4, 36),
    createFermentable("Rice Extract Syrup", "US", "Extract", 7, 32),
    createFermentable("Rice, Flaked", "US", "Grain", 1, 32),
    createFermentable("Roasted Barley", "US", "Grain", 300, 25),
    createFermentable("Rye Malt", "US", "Grain", 5, 29),
    createFermentable("Rye, Flaked", "US", "Grain", 2, 36),
    createFermentable("Smoked Malt", "Germany", "Grain", 9, 37),
    createFermentable("Special B Malt", "Belgium", "Grain", 180, 30),
    createFermentable("Special Roast", "US", "Grain", 50, 33),
    createFermentable("Sucrose", "US", "Sugar", 1, 46),
    createFermentable("Toasted Malt", "United Kingdom", "Grain", 27, 33),
    createFermentable("Turbinado", "United Kingdom", "Sugar", 10, 44),
    createFermentable("Victory Malt", "US", "Grain", 25, 34),
    createFermentable("Vienna Malt", "Germany", "Grain", 4, 36),
    createFermentable("Wheat Dry Extract", "US", "Dry Extract", 8, 44),
    createFermentable("Wheat Liquid Extract", "US", "Extract", 8, 36),
    createFermentable("Wheat Malt, Bel", "Belgium", "Grain", 2, 37),
    createFermentable("Wheat Malt, Dark", "Germany", "Grain", 9, 39),
    createFermentable("Wheat Malt, Ger", "Germany", "Grain", 2, 39),
    createFermentable("Wheat, Flaked", "US", "Grain", 2, 35),
    createFermentable("Wheat, Roasted", "Germany", "Grain", 425, 25),
    createFermentable("Wheat, Torrified", "US", "Grain", 2, 36),
    createFermentable("White Wheat Malt", "US", "Grain", 2, 40)
];

export const hops = [
  createHop("Ahtanum™", 6, 5.75, "American", "Citrus,Floral,Earthy", "Aroma"),
  createHop("Amarillo®", 9.5, 6.5, "American", "Citrus,Floral,Tropical Fruit", "Aroma"),
  createHop("Apollo", 19.5, NaN, "American", "", "Bittering"),
  createHop("Aquila", 6.75, NaN, "American", "", "Aroma"),
  createHop("Australian Ella", 15, 5.75, "Australian", "Floral,Spicy,Herbal", "Aroma"),
  createHop("Australian Galaxy", 13.5, 5.95, "Australian", "Fruity,Citrus,Stone Fruit", "Aroma"),
  createHop("Australian Helga", 6.1, 6, "Australian", "Fruity,Floral,Herbal", "Aroma"),
  createHop("Australian Pride Of Ringwood", 9.55, 5.7, "Australian", "Earthy,Cedar,Herbal", "Bittering"),
  createHop("Australian Summer", 6, 5.45, "Australian", "Citrus,Stone Fruit,Tropical Fruit", "All Purpose"),
  createHop("Australian Super Pride", 14.4, 7, "Australian", "Fruity,Earthy,Cedar", "Bittering"),
  createHop("Australian Sylva", 6.45, 3.8, "Australian", "Floral,Herbal", "Aroma"),
  createHop("Australian Topaz", 15.7, 7.15, "Australian", "Earthy,Tropical Fruit,Grassy", "All Purpose"),
  createHop("Australian Vic Secret", 15.5, NaN, "Australian", "", "Unknown"),
  createHop("Banner", NaN, NaN, "Unknown", "", "Unknown"),
  createHop("Belma", 12.1, NaN, "American", "", "All Purpose"),
  createHop("Bitter Gold", 17.1, 7.05, "Unknown", "Stone Fruit,Tropical Fruit", "All Purpose"),
  createHop("Bravo", 15.5, 4, "Unknown", "Fruity,Floral", "Bittering"),
  createHop("Brewer's Gold", 9, 4, "American", "Fruity,Spicy", "Bittering"),
  createHop("Bullion", 9.8, 6.4, "Unknown", "Fruity", "Bittering"),
  createHop("Calypso", 11, NaN, "Unknown", "", "All Purpose"),
  createHop("Cascade", 5.75, 5.75, "American", "Fruity,Citrus,Floral", "Aroma"),
  createHop("Cashmere", 8.4, 6.75, "Unknown", "Fruity,Citrus,Herbal", "All Purpose"),
  createHop("Centennial", 10.5, 4, "American", "Citrus,Floral", "All Purpose"),
  createHop("Chelan", 13.25, 9.15, "Unknown", "Citrus,Floral", "Bittering"),
  createHop("Chinook", 13, 3.5, "American", "Citrus,Spicy,Pine", "All Purpose"),
  createHop("Citra®", 12, 4, "Unknown", "Fruity,Citrus,Tropical Fruit", "Aroma"),
  createHop("Cluster", 7, 5, "Unknown", "Floral,Spicy", "All Purpose"),
  createHop("Columbia", 9.15, 4.25, "American", "Fruity,Earthy", "Aroma"),
  createHop("Columbus", 15, 4.5, "American", "Citrus,Spicy", "Bittering"),
  createHop("Comet", 10, 5.05, "American", "Citrus,Earthy", "Bittering"),
  createHop("Crystal", 4.5, 5.6, "American", "Floral,Spicy", "Aroma"),
  createHop("Czech Premiant", 9, 4.75, "Czech", "Fruity,Floral,Herbal", "Aroma"),
  createHop("Czech Saaz", 3.5, 7.5, "Czech", "Floral,Earthy,Herbal", "Aroma"),
  createHop("Czech Sladek", 7, 9.5, "Czech", "Citrus,Stone Fruit,Tropical Fruit", "Aroma"),
  createHop("El Dorado", 15, NaN, "American", "", "Unknown"),
  createHop("Equinox™", 15, 4.85, "Unknown", "Citrus,Herbal,Tropical Fruit", "Aroma"),
  createHop("Eroica", 11.1, 4.15, "Unknown", "Fruity", "Bittering"),
  createHop("EXP - HBC 291", 12.15, 5.6, "Unknown", "Fruity,Floral,Spicy", "All Purpose"),
  createHop("EXP - HBC 342", NaN, NaN, "Unknown", "Fruity,Tropical Fruit", "All Purpose"),
  createHop("Falconer's Flight® 7C’s", 9.75, 4.9, "Unknown", "Fruity,Citrus,Spicy,Earthy,Floral", "Aroma"),
  createHop("Falconer’s Flight®", 10.75, 4.5, "Unknown", "Citrus,Floral,Tropical Fruit", "Aroma"),
  createHop("French Aramis", 8.1, 4.15, "French", "Citrus,Spicy,Herbal", "Aroma"),
  createHop("French Strisselpalt", 2.15, 4.5, "French", "Citrus,Floral,Herbal", "Aroma"),
  createHop("French Triskel", 2.6, ~6.7, "French", "Fruity,Citrus,Floral", "Aroma"),
  createHop("Fuggle", 4.75, 1.75, "American", "Fruity,Floral,Earthy", "Aroma"),
  createHop("Galena", 13, 8, "American", "Citrus,Stone Fruit,Spicy", "Bittering"),
  createHop("German Brewer's Gold", 7, 3, "German", "Fruity,Spicy", "Bittering"),
  createHop("German Emerald", 5, 4.5, "German", "Fruity,Floral", "Aroma"),
  createHop("German Hallertau Blanc", 10.5, 5, "German", "Fruity,Grassy,Floral,Grassy", "Aroma"),
  createHop("German Hallertau Mittelfrüh", 4.25, 4, "German", "Citrus,Floral,Spicy", "Aroma"),
  createHop("German Hallertauer Gold", 6.25, NaN, "German", "", "Aroma"),
  createHop("German Herkules", 14.5, 4.75, "German", "Floral,Spicy,Pine", "Bittering"),
  createHop("German Hersbruker", 3.5, 4.25, "German", "Fruity,Floral,Herbal", "Aroma"),
  createHop("German Hull Melon", 7.2, 7.6, "German", "Fruity,Tropical Fruit", "Aroma"),
  createHop("German Magnum", 13.5, 6, "German", "Fruity,Floral", "Bittering"),
  createHop("German Mandarina Bavaria", 8.5, 5.75, "German", "Fruity,Citrus,Spicy,Tropical Fruit", "Aroma"),
  createHop("German Merkur", 13.5, 5.25, "German", "Citrus,Floral,Earthy", "Bittering"),
  createHop("German Northern Brewer", 8.5, 4.25, "German", "Floral,Spicy,Herbal", "All Purpose"),
  createHop("German Opal", 6.5, 4.5, "German", "Fruity,Floral,Herbal", "Aroma"),
  createHop("German Perle", 8, 3.75, "German", "Fruity,Floral", "Aroma"),
  createHop("German Polaris", 20.5, 5.25, "German", "Floral,Herbal", "Aroma"),
  createHop("German Saphir", 3.25, 5.5, "German", "Fruity,Citrus,Spicy", "Aroma"),
  createHop("German Spalt Select", 4.75, 3.75, "German", "Fruity,Floral", "Aroma"),
  createHop("German Spalt", 4, 4, "German", "Fruity,Floral,Herbal", "Aroma"),
  createHop("German Taurus", 14.5, NaN, "German", "", "Bittering"),
  createHop("German Tettnanger", 4.5, 4, "German", "Floral,Earthy,Herbal", "Aroma"),
  createHop("German Tradition", 6, 4.5, "German", "Floral,Herbal,Grassy", "Aroma"),
  createHop("Glacier", 5.5, ~8.2, "Unknown", "Herbal", "All Purpose"),
  createHop("Golding", 5, 2.5, "American", "Spicy,Floral", "Aroma"),
  createHop("Hallertauer", 4.5, 4.5, "American", "Floral,Spicy,Herbal", "Aroma"),
  createHop("Horizon", 12, 7.5, "Unknown", "Floral,Spicy", "All Purpose"),
  createHop("Legacy", NaN, NaN, "American", "Fruity,Floral,Spicy", "All Purpose"),
  createHop("Liberty", 4, 3.5, "American", "Citrus,Spicy", "Aroma"),
  createHop("Magnum", 12, NaN, "American", "", "Bittering"),
  createHop("Millennium", 15.5, 4.8, "American", "Floral,Earthy,Herbal", "Bittering"),
  createHop("Mosaic™", 12.5, 3.55, "Unknown", "Citrus,Floral,Grassy", "Aroma"),
  createHop("Mt. Hood", 6, 6.25, "American", "Spicy,Earthy,Herbal", "Aroma"),
  createHop("Mt. Rainier", 6.55, 6, "American", "Citrus,Floral,Spicy", "All Purpose"),
  createHop("New Zealand Cascade", 7, NaN, "New Zealand", "Pine", "Aroma"),
  createHop("New Zealand Dr. Rudi", 11, 7.75, "New Zealand", "Citrus,Pine", "All Purpose"),
  createHop("New Zealand Green Bullet", 12.5, 6.75, "New Zealand", "Spicy", "Bittering"),
  createHop("New Zealand Kohatu", 3, NaN, "New Zealand", "Floral,Pine,Tropical Fruit", "Aroma"),
  createHop("New Zealand Motueka", 7.65, 5.25, "New Zealand", "Fruity,Tropical Fruit,Citrus", "Aroma"),
  createHop("New Zealand Nelson Sauvin", 12.5, 7, "New Zealand", "Fruity,Tropical Fruit", "All Purpose"),
  createHop("New Zealand Pacific Gem", 14.25, 8, "New Zealand", "Floral", "Bittering"),
  createHop("New Zealand Pacific Jade", 13, 7.5, "New Zealand", "Citrus,Spicy,Herbal", "All Purpose"),
  createHop("New Zealand Pacifica", 5.5, ~6.0, "New Zealand", "Citrus,Floral", "Aroma"),
  createHop("New Zealand Rakau", 10.5, 5.5, "New Zealand", "Stone Fruit,Pine", "All Purpose"),
  createHop("New Zealand Riwaka", 5.5, NaN, "New Zealand", "Citrusy", "Aroma"),
  createHop("New Zealand Southern Cross", 12.5, 5.5, "New Zealand", "Citrus,Spicy,Pine", "All Purpose"),
  createHop("New Zealand Sticklebract", 13.6, 8, "New Zealand", "Citrus,Pine", "All Purpose"),
  createHop("New Zealand Super Alpha", 11, NaN, "New Zealand", "Earthy,Pine", "Bittering"),
  createHop("New Zealand Wai-iti", 3.4, NaN, "New Zealand", "Citrus", "Aroma"),
  createHop("New Zealand Waimea", 17.5, 8, "New Zealand", "Citrus,Pine", "Aroma"),
  createHop("New Zealand Wakatu", 7.5, ~8.5, "New Zealand", "Floral", "All Purpose"),
  createHop("New Zealand Hallertau", 7.5, NaN, "New Zealand", "", "All Purpose"),
  createHop("Newport", 15, NaN, "American", "", "Unknown"),
  createHop("New Zealand Orbit", NaN, NaN, "New Zealand", "", "Unknown"),
  createHop("Northern Brewer", 9, 4, "American", "Earthy,Pine", "All Purpose"),
  createHop("Nugget", 13.25, 5, "American", "Spicy,Herbal", "Bittering"),
  createHop("Olympic", 12.2, 4.95, "American", "Citrus,Spicy", "Bittering"),
  createHop("Palisade®", 7.5, 7, "Unknown", "Stone Fruit,Herbal,Grassy", "Aroma"),
  createHop("Perle", 8.25, 4.5, "American", "Floral,Herbal", "All Purpose"),
  createHop("Polish Lublin", 3.75, 3, "Polish", "Spicy,Herbal", "Aroma"),
  createHop("Saaz", 3.75, 3.75, "American", "Spicy,Earthy", "Aroma"),
  createHop("Santiam", 6, 7.25, "American", "Floral,Spicy,Herbal", "Aroma"),
  createHop("Satus", 13.25, NaN, "American", "", "All Purpose"),
  createHop("Simcoe®", 13, 4.5, "American", "Citrus,Earthy,Pine", "All Purpose"),
  createHop("Sorachi Ace", 13, 6.5, "Japan", "Citrus,Herbal,Grassy", "All Purpose"),
  createHop("Sterling", 7.5, 5, "American", "Citrus,Floral,Spicy", "Aroma"),
  createHop("Styrian Aurora", 8.25, 3.55, "Slovenia", "Floral,Pine", "Aroma"),
  createHop("Styrian Bobek", 5.25, 5.05, "Slovenia", "Floral,Pine", "Aroma"),
  createHop("Styrian Celeia", 4.5, 2.65, "Slovenia", "Floral", "Aroma"),
  createHop("Styrian Golding", 5.25, NaN, "Slovenia", "Spicy", "Unknown"),
  createHop("Styrian Savinjski Golding", 4.15, 2.5, "Slovenia", "Fruity,Floral,Earthy,Herbal", "Aroma"),
  createHop("Summit™", 17.5, 4.65, "Unknown", "Citrus,Spicy,Earthy", "Bittering"),
  createHop("Sun", 14, NaN, "Unknown", "", "Bittering"),
  createHop("Tahoma", 7.7, 9, "Unknown", "Citrus,Cedar,Pine", "Aroma"),
  createHop("Tettnanger", 4.5, 3.75, "American", "Spicy", "Aroma"),
  createHop("Tomahawk", 16, NaN, "American", "", "Bittering"),
  createHop("UK Admiral", 14.5, 5, "British", "Citrus,Earthy,Herbal", "Bittering"),
  createHop("UK Boadicea", 8.5, 3.5, "British", "Floral,Spicy,Grassy", "All Purpose"),
  createHop("UK Bramling Cross", 7, 2.9, "British", "Citrus,Fruity,Spicy", "All Purpose"),
  createHop("UK Bullion", 7.75, NaN, "British", "", "Bittering"),
  createHop("UK Challenger", 7.75, 3.7, "British", "Floral,Spicy,Cedar", "All Purpose"),
  createHop("UK East Kent Golding", 5, 2.45, "British", "Floral,Spicy,Earthy", "All Purpose"),
  createHop("UK First Gold", 8.25, 3.75, "British", "Citrus,Floral,Spicy", "All Purpose"),
  createHop("UK Fuggle", 5, 3, "British", "Grassy,Floral", "Aroma"),
  createHop("UK Northdown", 8.5, 4.5, "British", "Spicy,Cedar,Pine", "All Purpose"),
  createHop("UK Phoenix", 10, 4.6, "British", "Citrus,Spicy,Pine", "All Purpose"),
  createHop("UK Pilgrim", 11, 4.5, "British", "Fruity,Citrus,Spicy", "All Purpose"),
  createHop("UK Pioneer", 9.25, 3.75, "British", "Citrus,Cedar,Herbal", "All Purpose"),
  createHop("UK Progress", 6.75, 2.65, "British", "Spicy,Earthy,Grassy", "Aroma"),
  createHop("UK Sovereign", 5.5, 2.6, "British", "Floral,Herbal,Grassy", "Aroma"),
  createHop("UK Target", 11, 4.85, "British", "Floral,Spicy,Pine", "Bittering"),
  createHop("UK WGV", 6.25, 3, "British", "Floral,Earthy,Herbal", "Aroma"),
  createHop("Ultra", 2.75, 3.75, "American", "Floral,Spicy", "Aroma"),
  createHop("Vanguard", 5.5, 6, "American", "Floral,Herbal", "Aroma"),
  createHop("Warrior®", 16, 5, "American", "Citrus,Herbal,Pine", "All Purpose"),
  createHop("Willamette", 4.7, 3.75, "American", "Floral,Spicy", "Aroma"),
  createHop("Yakima Cluster", 7.25, NaN, "American", "", "Bittering"),
  createHop("Zeus", 15, NaN, "American", "", "Aroma"),
  createHop("Spalt Select", 4, NaN, "American", "", "All Purpose"),
  createHop("German Hallertauer Tradition", 4.25, NaN, "German", "", "Aroma"),
  createHop("UK Herald", 12, NaN, "British", "", "All Purpose"),
  createHop("Northwest Golding", 4.5, NaN, "Unknown", "", "Aroma"),
  createHop("UK Kent Golding", 4.75, NaN, "British", "", "Aroma"),
  createHop("Argentina Cascade", 4, NaN, "Argentina", "Citrus,Spicy", "Unknown"),
  createHop("Polish Marynka", 10.5, NaN, "Polish", "", "Aroma"),
  createHop("German Smaragd", 5, NaN, "German", "", "Aroma")
]

export const GU_WATER = 1000;

export const SRM_to_hex = [
  "",
  "#FFE699",
  "#FFD878",
  "#FFCA5A",
  "#FFBF42",
  "#FBB123",
  "#F8A600",
  "#F39C00",
  "#EA8F00",
  "#E58500",
  "#DE7C00",
  "#D77200",
  "#CF6900",
  "#CB6200",
  "#C35900",
  "#BB5100",
  "#B54C00",
  "#B04500",
  "#A63E00",
  "#A13700",
  "#9B3200",
  "#952D00",
  "#8E2900",
  "#882300",
  "#821E00",
  "#7B1A00",
  "#771900",
  "#701400",
  "#6A0E00",
  "#660D00",
  "#5E0B00",
  "#5A0A02",
  "#600903",
  "#520907",
  "#4C0505",
  "#470606",
  "#440607",
  "#3F0708",
  "#3B0607",
  "#3A070B",
  "#36080A" ];