import * as React from 'react';
import {styled, SliderRail, SliderTrack, SliderThumb, SliderMark, SliderMarkLabel, SliderValueLabel} from '@mui/material';

import PropTypes from 'prop-types';
import sliderClasses, { getSliderUtilityClass } from '@mui/material/Slider/sliderClasses';

import capitalize from '@mui/material/utils/capitalize';

import clsx from 'clsx';
import {
  isHostComponent,
  useSlotProps,
  unstable_composeClasses as composeClasses,
} from '@mui/base';

import { valueToPercent } from '@mui/base/useSlider';
import useSlider from '@mui/base/useSlider';
import useThemeProps from '@mui/material/styles/useThemeProps';
import useTheme from '@mui/material/styles/useTheme';
import shouldSpreadAdditionalProps from '@mui/material/utils/shouldSpreadAdditionalProps';

// Taken and modifed from MUI to support directional labels. 
// ownerstate.valueLabelPosition sets the orientation of the label.
// TODO: commit back to MUI?
const StyledSliderValueLabel = styled(SliderValueLabel, {
    name: 'MuiSlider',
    slot: 'ValueLabel',
    overridesResolver: (props, styles) => styles.valueLabel,
  })(({ theme, ownerState, valueLabelPosition}) => ({
    [`&.${sliderClasses.valueLabelOpen}`]: {
      transform: `${ownerState.orientation === 'vertical' ? 'translateY(-50%)' : 'translateY(-100%)'
        } scale(1)`,
    },
    zIndex: 1,
    whiteSpace: 'nowrap',
    ...theme.typography.body2,
    fontWeight: 500,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shortest,
    }),
    transform: `${ownerState.orientation === 'vertical' ? 'translateY(-50%)' : 'translateY(-100%)'
      } scale(0)`,
    position: 'absolute',
    backgroundColor: (theme.vars || theme).palette.grey[600],
    borderRadius: 2,
    color: (theme.vars || theme).palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25rem 0.75rem',
    ...(ownerState.orientation === 'horizontal' && valueLabelPosition === "top" && {
      top: '-10px',
      transformOrigin: 'bottom center',
      '&:before': {
        position: 'absolute',
        content: '""',
        width: 8,
        height: 8,
        transform: 'translate(-50%, 50%) rotate(45deg)',
        backgroundColor: 'inherit',
        bottom: 0,
        left: '50%',
      },
    }),
    ...(ownerState.orientation === 'horizontal' && valueLabelPosition === "bottom" && {
      top: '60px',
      transformOrigin: 'bottom center',
      '&:before': {
        position: 'absolute',
        content: '""',
        width: 8,
        height: 8,
         transform: 'translate(-50%, -50%) rotate(45deg)',
        backgroundColor: 'inherit',
        top: 0,
        left: '50%',
      },
    }),

    ...(ownerState.orientation === 'vertical' && {
      right: ownerState.size === 'small' ? '20px' : '30px',
      top: '50%',
      transformOrigin: 'right center',
      '&:before': {
        position: 'absolute',
        content: '""',
        width: 8,
        height: 8,
        transform: 'translate(-50%, -50%) rotate(45deg)',
        backgroundColor: 'inherit',
        right: -8,
        top: '50%',
      },
    }),
    ...(ownerState.size === 'small' && {
      fontSize: theme.typography.pxToRem(12),
      padding: '0.25rem 0.5rem',
    }),
  }));
  
  StyledSliderValueLabel.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * @ignore
     */
    children: PropTypes.node,
  };

  const SliderRoot = styled('span', {
    name: 'MuiSlider',
    slot: 'Root',
    overridesResolver: (props, styles) => {
      const { ownerState } = props;
  
      return [
        styles.root,
        styles[`color${capitalize(ownerState.color)}`],
        ownerState.size !== 'medium' && styles[`size${capitalize(ownerState.size)}`],
        ownerState.marked && styles.marked,
        ownerState.orientation === 'vertical' && styles.vertical,
        ownerState.track === 'inverted' && styles.trackInverted,
        ownerState.track === false && styles.trackFalse,
      ];
    },
  })(({ theme, ownerState }) => ({
    borderRadius: 12,
    boxSizing: 'content-box',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    touchAction: 'none',
    color: (theme.vars || theme).palette[ownerState.color].main,
    WebkitTapHighlightColor: 'transparent',
    ...(ownerState.orientation === 'horizontal' && {
      height: 4,
      width: '100%',
      padding: '13px 0',
      // The primary input mechanism of the device includes a pointing device of limited accuracy.
      '@media (pointer: coarse)': {
        // Reach 42px touch target, about ~8mm on screen.
        padding: '20px 0',
      },
      ...(ownerState.size === 'small' && {
        height: 2,
      }),
      ...(ownerState.marked && {
        marginBottom: 20,
      }),
    }),
    ...(ownerState.orientation === 'vertical' && {
      height: '100%',
      width: 4,
      padding: '0 13px',
      // The primary input mechanism of the device includes a pointing device of limited accuracy.
      '@media (pointer: coarse)': {
        // Reach 42px touch target, about ~8mm on screen.
        padding: '0 20px',
      },
      ...(ownerState.size === 'small' && {
        width: 2,
      }),
      ...(ownerState.marked && {
        marginRight: 44,
      }),
    }),
    '@media print': {
      colorAdjust: 'exact',
    },
    [`&.${sliderClasses.disabled}`]: {
      pointerEvents: 'none',
      cursor: 'default',
      color: (theme.vars || theme).palette.grey[400],
    },
    [`&.${sliderClasses.dragging}`]: {
      [`& .${sliderClasses.thumb}, & .${sliderClasses.track}`]: {
        transition: 'none',
      },
    },
  }));
  
  SliderRoot.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * @ignore
     */
    children: PropTypes.node,
  };


  //Unchanged
  function Identity(x) {
    return x;
  }

  //Unchanged
  const useUtilityClasses = (ownerState) => {
    const { disabled, dragging, marked, orientation, track, classes, color, size } = ownerState;
  
    const slots = {
      root: [
        'root',
        disabled && 'disabled',
        dragging && 'dragging',
        marked && 'marked',
        orientation === 'vertical' && 'vertical',
        track === 'inverted' && 'trackInverted',
        track === false && 'trackFalse',
        color && `color${capitalize(color)}`,
        size && `size${capitalize(size)}`,
      ],
      rail: ['rail'],
      track: ['track'],
      mark: ['mark'],
      markActive: ['markActive'],
      markLabel: ['markLabel'],
      markLabelActive: ['markLabelActive'],
      valueLabel: ['valueLabel'],
      thumb: [
        'thumb',
        disabled && 'disabled',
        size && `thumbSize${capitalize(size)}`,
        color && `thumbColor${capitalize(color)}`,
      ],
      active: ['active'],
      disabled: ['disabled'],
      focusVisible: ['focusVisible'],
    };
  
    return composeClasses(slots, getSliderUtilityClass, classes);
  };

  
  const Forward = ({ children }) => children;
  
  const Slider = React.forwardRef(function Slider(inputProps, ref) {
    const props = useThemeProps({ props: inputProps, name: 'MuiSlider' });
  
    const theme = useTheme();
    const isRtl = theme.direction === 'rtl';
  
    const {
      'aria-label': ariaLabel,
      'aria-valuetext': ariaValuetext,
      'aria-labelledby': ariaLabelledby,
      // eslint-disable-next-line react/prop-types
      component = 'span',
      components = {},
      componentsProps = {},
      color = 'primary',
      classes: classesProp,
      className,
      disableSwap = false,
      disabled = false,
      getAriaLabel,
      getAriaValueText,
      marks: marksProp = false,
      max = 100,
      min = 0,
      name,
      onChange,
      onChangeCommitted,
      orientation = 'horizontal',
      size = 'medium',
      step = 1,
      scale = Identity,
      slotProps,
      slots,
      tabIndex,
      track = 'normal',
      value: valueProp,
      valueLabelDisplay = 'off',
      valueLabelFormat = Identity,
      valueLabelPosition = "top",
      ...other
    } = props;
  
    const ownerState = {
      ...props,
      isRtl,
      max,
      min,
      classes: classesProp,
      disabled,
      disableSwap,
      orientation,
      marks: marksProp,
      color,
      size,
      step,
      scale,
      track,
      valueLabelDisplay,
      valueLabelFormat,
    };
  
    const {
      axisProps,
      getRootProps,
      getHiddenInputProps,
      getThumbProps,
      open,
      active,
      axis,
      focusedThumbIndex,
      range,
      dragging,
      marks,
      values,
      trackOffset,
      trackLeap,
      getThumbStyle,
    } = useSlider({ ...ownerState, rootRef: ref });
  
    ownerState.marked = marks.length > 0 && marks.some((mark) => mark.label);
    ownerState.dragging = dragging;
    ownerState.focusedThumbIndex = focusedThumbIndex;
  
    const classes = useUtilityClasses(ownerState);
  
    // support both `slots` and `components` for backward compatibility
    const RootSlot = slots?.root ?? components.Root ?? SliderRoot;
    const RailSlot = slots?.rail ?? components.Rail ?? SliderRail;
    const TrackSlot = slots?.track ?? components.Track ?? SliderTrack;
    const ThumbSlot = slots?.thumb ?? components.Thumb ?? SliderThumb;
    const ValueLabelSlot = slots?.valueLabel ?? components.ValueLabel ?? StyledSliderValueLabel;
    const MarkSlot = slots?.mark ?? components.Mark ?? SliderMark;
    const MarkLabelSlot = slots?.markLabel ?? components.MarkLabel ?? SliderMarkLabel;
    const InputSlot = slots?.input ?? components.Input ?? 'input';
  
    const rootSlotProps = slotProps?.root ?? componentsProps.root;
    const railSlotProps = slotProps?.rail ?? componentsProps.rail;
    const trackSlotProps = slotProps?.track ?? componentsProps.track;
    const thumbSlotProps = slotProps?.thumb ?? componentsProps.thumb;
    const valueLabelSlotProps = slotProps?.valueLabel ?? componentsProps.valueLabel;
    const markSlotProps = slotProps?.mark ?? componentsProps.mark;
    const markLabelSlotProps = slotProps?.markLabel ?? componentsProps.markLabel;
    const inputSlotProps = slotProps?.input ?? componentsProps.input;
  
    const rootProps = useSlotProps({
      elementType: RootSlot,
      getSlotProps: getRootProps,
      externalSlotProps: rootSlotProps,
      externalForwardedProps: other,
      additionalProps: {
        ...(shouldSpreadAdditionalProps(RootSlot) && {
          as: component,
        }),
      },
      ownerState: {
        ...ownerState,
        ...rootSlotProps?.ownerState,
      },
      className: [classes.root, className],
    });
  
    const railProps = useSlotProps({
      elementType: RailSlot,
      externalSlotProps: railSlotProps,
      ownerState,
      className: classes.rail,
    });
  
    const trackProps = useSlotProps({
      elementType: TrackSlot,
      externalSlotProps: trackSlotProps,
      additionalProps: {
        style: {
          ...axisProps[axis].offset(trackOffset),
          ...axisProps[axis].leap(trackLeap),
        },
      },
      ownerState: {
        ...ownerState,
        ...trackSlotProps?.ownerState,
      },
      className: classes.track,
    });
  
    const thumbProps = useSlotProps({
      elementType: ThumbSlot,
      getSlotProps: getThumbProps,
      externalSlotProps: thumbSlotProps,
      ownerState: {
        ...ownerState,
        ...thumbSlotProps?.ownerState,
      },
      className: classes.thumb,
    });
  
    const valueLabelProps = useSlotProps({
      elementType: ValueLabelSlot,
      externalSlotProps: valueLabelSlotProps,
      ownerState: {
        ...ownerState,
        ...valueLabelSlotProps?.ownerState,
      },
      className: classes.valueLabel,
    });
  
    const markProps = useSlotProps({
      elementType: MarkSlot,
      externalSlotProps: markSlotProps,
      ownerState,
      className: classes.mark,
    });
  
    const markLabelProps = useSlotProps({
      elementType: MarkLabelSlot,
      externalSlotProps: markLabelSlotProps,
      ownerState,
      className: classes.markLabel,
    });
  
    const inputSliderProps = useSlotProps({
      elementType: InputSlot,
      getSlotProps: getHiddenInputProps,
      externalSlotProps: inputSlotProps,
      ownerState,
    });
  
    return (
      <RootSlot {...rootProps}>
        <RailSlot {...railProps} />
        <TrackSlot {...trackProps} />
        {marks
          .filter((mark) => mark.value >= min && mark.value <= max)
          .map((mark, index) => {
            const percent = valueToPercent(mark.value, min, max);
            const style = axisProps[axis].offset(percent);
  
            let markActive;
            if (track === false) {
              markActive = values.indexOf(mark.value) !== -1;
            } else {
              markActive =
                (track === 'normal' &&
                  (range
                    ? mark.value >= values[0] && mark.value <= values[values.length - 1]
                    : mark.value <= values[0])) ||
                (track === 'inverted' &&
                  (range
                    ? mark.value <= values[0] || mark.value >= values[values.length - 1]
                    : mark.value >= values[0]));
            }
  
            return (
              <React.Fragment key={index}>
                <MarkSlot
                  data-index={index}
                  {...markProps}
                  {...(!isHostComponent(MarkSlot) && {
                    markActive,
                  })}
                  style={{ ...style, ...markProps.style }}
                  className={clsx(markProps.className, {
                    [classes.markActive]: markActive,
                  })}
                />
                {mark.label != null ? (
                  <MarkLabelSlot
                    aria-hidden
                    data-index={index}
                    {...markLabelProps}
                    {...(!isHostComponent(MarkLabelSlot) && {
                      markLabelActive: markActive,
                    })}
                    style={{ ...style, ...markLabelProps.style }}
                    className={clsx(classes.markLabel, markLabelProps.className, {
                      [classes.markLabelActive]: markActive,
                    })}
                  >
                    {mark.label}
                  </MarkLabelSlot>
                ) : null}
              </React.Fragment>
            );
          })}
        {values.map((value, index) => {
          const percent = valueToPercent(value, min, max);
          const style = axisProps[axis].offset(percent);
  
          const ValueLabelComponent = valueLabelDisplay === 'off' ? Forward : ValueLabelSlot;
  
          return (
            /* TODO v6: Change component structure. It will help in avoiding the complicated React.cloneElement API added in SliderValueLabel component. Should be: Thumb -> Input, ValueLabel. Follow Joy UI's Slider structure. */
            <ValueLabelComponent
              key={index}
              {...(!isHostComponent(ValueLabelComponent) && {
                valueLabelFormat,
                valueLabelDisplay,
                value:
                  typeof valueLabelFormat === 'function'
                    ? valueLabelFormat(scale(value), index)
                    : valueLabelFormat,
                index,
                open: open === index || active === index || valueLabelDisplay === 'on',
                disabled,
                valueLabelPosition:
                  typeof valueLabelPosition === 'function'
                    ? valueLabelPosition(index)
                    : valueLabelPosition
              })}
              {...valueLabelProps}
            >
              <ThumbSlot
                data-index={index}
                {...thumbProps}
                className={clsx(classes.thumb, thumbProps.className, {
                  [classes.active]: active === index,
                  [classes.focusVisible]: focusedThumbIndex === index,
                })}
                style={{
                  ...style,
                  ...getThumbStyle(index),
                  ...thumbProps.style,
                }}
              >
                <InputSlot
                  data-index={index}
                  aria-label={getAriaLabel ? getAriaLabel(index) : ariaLabel}
                  aria-valuenow={scale(value)}
                  aria-labelledby={ariaLabelledby}
                  aria-valuetext={
                    getAriaValueText ? getAriaValueText(scale(value), index) : ariaValuetext
                  }
                  value={values[index]}
                  {...inputSliderProps}
                />
              </ThumbSlot>
            </ValueLabelComponent>
          );
        })}
      </RootSlot>
    );
  });
  
  export {Slider as DirectionalLabelSlider};