import * as React from "react";
import * as MUI from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import RecipeIcon from '@mui/icons-material/SportsBar';
import SugarIcon from '@mui/icons-material/Grain';
import HopIcon from '@mui/icons-material/Spa';

import { ReactComponent as AppLogo } from '../../res/AppLogo.svg'

import * as RecipeIO from '../RecipeIO'


export const tabs = [
  { name: "General Recipe", tooltip: "General Recipe Page - add yeasts, water quantities, etc", icon: RecipeIcon },
  { name: "Fermentables", tooltip: "Calculate required quantities of Sugars/Grains", icon: SugarIcon },
  { name: "Hops", tooltip: "Add Hops, calculate Bitterness, etc", icon: HopIcon }
];

export default function TabBar({ handleTabChange, current_tab }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    if (anchorElNav != null) {
      setAnchorElNav(null);

      handleTabChange(tabs.findIndex(tab => {
        return tab.name === event.target.textContent;
      }));
    }
  };

  return (
    <MUI.AppBar position="static">
      <MUI.Container maxWidth="xl">
        <MUI.Toolbar disableGutters>
          {/* Large Logo */}
          <MUI.Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <AppLogo />
          </MUI.Box>

          {/* Large Title */}
          <MUI.Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none"
            }}
          >
            BREWKING
          </MUI.Typography>
          
          {/* Small Tab Hamburger Menu */}
          <MUI.Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <MUI.IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </MUI.IconButton>
            <MUI.Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" }
              }}
            >

              {
                React.Children.toArray(
                  tabs.map((page) => (
                    <MUI.Tooltip title={page.tooltip} placement="right" arrow>
                      <MUI.MenuItem key={page} onClick={handleCloseNavMenu}>
                        <MUI.Stack spacing={1} direction={"row"}>
                          <page.icon key={page} />
                          <MUI.Typography textAlign="center" >{page.name}</MUI.Typography>
                        </MUI.Stack>
                      </MUI.MenuItem>
                    </MUI.Tooltip>

                  ))
                )
              }
            </MUI.Menu>
          </MUI.Box>
          
          {/* Small Logo */}
          <MUI.Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <AppLogo />
          </MUI.Box>
            
          {/* Small Logo   */}
          <MUI.Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none"
            }}
          >
            BREWKING
          </MUI.Typography>
          
          {/* Big Menu */}
          <MUI.Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>

            {
              //allows React to automagically add a key to children, avoiding warnings and improving render efficency
              React.Children.toArray(
                tabs.map((page) => (
                  <MUI.Tooltip title={page.tooltip} arrow>
                    <MUI.ToggleButtonGroup
                      value={tabs[current_tab].name}
                      exclusive
                      onChange={handleTabChange}
                      color="secondary"
                    >
                      {/* Tabs in large mode */}
                      <MUI.ToggleButton
                        key={page}
                        //TODO: can this onClick function be removed and the tab select functionality moved into the above ToggleButtonGroup onChange?
                        onClick={handleCloseNavMenu}
                        sx={{ m: 1, color: "white", border: "none" }}
                        value={page.name}
                      >
                        <MUI.Stack spacing={.4} direction={"row"}>
                          <page.icon fontSize="small" />
                          <MUI.Typography>{page.name}</MUI.Typography>
                        </MUI.Stack>
                      </MUI.ToggleButton>
                    </MUI.ToggleButtonGroup>
                  </MUI.Tooltip>
                ))
              )
            }
          </MUI.Box>
          
          {/* Metric Toggle */}
          <RecipeIO.MetricToggle color="secondary"/>
          
        </MUI.Toolbar>
      </MUI.Container>
    </MUI.AppBar>
  );
}