import * as React from 'react';
import * as MUI from '@mui/material';

import * as BeerData from '../../BeerData';
import HopsTable from '../forms/HopTable';
import RecipeHopDetails from '../forms/RecipeHopDetails';

function groupHopsByType(hops) {
  var reducer = function(groups, hop) {
    var group = groups[hop.purpose] || (groups[hop.purpose] = []);
    group.push(hop.name);
    return groups;
  };
  return hops.reduce(reducer, {});
}

//TODO: this will need to be able to be recalculated when a user adds one (not globally const)
export const type_grouped_hops = groupHopsByType(BeerData.hops);


export default function GeneralTab(props) {
    const {children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        { value === index && (
          <MUI.Stack m={2} spacing={2} direction="row" justifyContent={'center'}> 
            <RecipeHopDetails/>
            <HopsTable/>
          </MUI.Stack>
        )}
      </div>
    );
  }