import * as React from 'react';
import * as MUI from '@mui/material';

import * as convert_units from 'convert-units';

import { RecipeContext } from '../Recipe'
import NumberField from './NumberField';
import * as BeerData from '../BeerData'

import BeerIcon from '@mui/icons-material/SportsBar';


export function QuantityField(props) {
    const { recipe_data, setRecipeData } = React.useContext(RecipeContext);

    return (
        <NumberField 
            {...props}
            id={"volume_field"}
            helperText="Total Batch Quantity"
            data={recipe_data.metric ? convert_units(recipe_data.batch_volume).from("gal").to("l") : recipe_data.batch_volume}
            endAdornment={<MUI.InputAdornment position="end">{recipe_data.metric ? "Liters" : "Gallons"}</MUI.InputAdornment>}
            setData={data => {
                setRecipeData({ ...recipe_data, batch_volume: recipe_data.metric ? convert_units(data).from("l").to("gal") : data });
              }}
          />

    );
}

export function OriginalGravityField(props) {
    const { recipe_data, setRecipeData } = React.useContext(RecipeContext);

    return (
        <MUI.TextField 
            {...props}
            id={"OG_field"}    
            value={recipe_data.original_gravity}
            helperText="Target Original Gravity"
            onChange={event => {
                setRecipeData({ ...recipe_data, original_gravity: event.target.value });
            }}
            InputProps={{
                endAdornment: <MUI.InputAdornment position="end">10XX</MUI.InputAdornment>,
            }}
        />
    );
}

export function FinalGravityField(props) {
    const { recipe_data, setRecipeData } = React.useContext(RecipeContext);

    return (
        <MUI.TextField 
            {...props}
            id={"FG_field"} 
            value={recipe_data.final_gravity}
            helperText="Target Final Gravity"
            onChange={event => {
                setRecipeData({ ...recipe_data, final_gravity: event.target.value });
            }}
            InputProps={{
                endAdornment: <MUI.InputAdornment aria-disabled position="end">10XX</MUI.InputAdornment>,
            }}
        />
    );
}

export function BatchABV(props) {
    const { recipe_data } = React.useContext(RecipeContext);

    return (
        <MUI.TextField 
            {...props}
            id={"batch_abv"}   
            value={recipe_data.batch_abv()} 
            color="success" 
            focused
            helperText="ABV"
            InputProps={{
                endAdornment: <MUI.InputAdornment position="end">%</MUI.InputAdornment>,
            }}
        />
    );
}


export function BatchRequiredSugarPerGallon(props) {
    const { recipe_data } = React.useContext(RecipeContext);

    return (
        <MUI.TextField 
            {...props}
            id={"batch_required_sugar_per_gallon"} 
            value={recipe_data.batch_required_sugar_per_gallon()} 
            color="success" 
            focused
            helperText="Required Sugar per Gallon the batch needs to hit OG (GU's)"
        />
    );
}

export function BatchRequiredSugar(props) {
    const { recipe_data } = React.useContext(RecipeContext);

    return (
        <MUI.TextField 
            {...props}
            id={"batch_required_sugar"}
            value={recipe_data.batch_required_sugar()} 
            color="success" 
            focused
            helperText="Total Sugar required for batch (GU's)"
        />
    );
}

export function BatchTotalIBUsField(props) {
    const { recipe_data, setRecipeData } = React.useContext(RecipeContext);
    
    // eslint-disable-next-line
    React.useEffect(() => {
        setRecipeData({ ...recipe_data, batch_total_IBUs: recipe_data.hops.reduce((total, { IBUs }) => total + IBUs, 0) });
        // eslint-disable-next-line
    }, [recipe_data.hops]);
  
    return (
        <MUI.TextField 
            {...props}
            id={"batch_required_sugar"}
            value={recipe_data.batch_total_IBUs.toFixed(2)} 
            color="success" 
            focused
            helperText="Recipe IBUs"
        />
    );
}

export function BatchBUGURatioField(props) {
    const { recipe_data } = React.useContext(RecipeContext);
  
    return (
        <MUI.TextField 
            {...props}
            id={"batch_required_sugar"}
            value={(recipe_data.batch_total_IBUs/(recipe_data.original_gravity-1000)).toFixed(2)} 
            color="success" 
            focused
            helperText="Bitterness : Sugar Ratio (BU:GU)"
        />
    );
}

export function SRMIndicatorIcon(props) {
    const { recipe_data, setRecipeData } = React.useContext(RecipeContext);

    // eslint-disable-next-line
    React.useEffect(() => {
        setRecipeData({ ...recipe_data, batch_total_SRM: recipe_data.fermentables.reduce((total, { imparted_SRM }) => total + Number(imparted_SRM), 0) });
        // eslint-disable-next-line
    }, [recipe_data.fermentables]);
      
  
    return (

        <MUI.Stack direction={'column'} spacing={.2} alignItems={'center'}>

            <MUI.Stack direction={'row'} spacing={2} alignItems={'center'}>  
                <BeerIcon htmlColor={BeerData.SRM_to_hex[recipe_data.batch_total_SRM]} fontSize='large' />
                <MUI.TextField 
                    {...props}
                    id={"batch_required_sugar"}
                    value={recipe_data.batch_total_SRM}
                    color="success" 
                    focused
                />
            </MUI.Stack>

        <MUI.FormHelperText variant='filled'>SRM</MUI.FormHelperText>
        </MUI.Stack>
    );
}


export function MetricToggle(props) {
    const { recipe_data, setRecipeData } = React.useContext(RecipeContext);

    return (    
        <MUI.FormControlLabel  
            control={<MUI.Switch
                {...props}
                checked={recipe_data.metric}
                onChange={event => {
                    setRecipeData({ ...recipe_data, metric: event.target.checked});
                }}
                />
            }
            label='Metric:'
            labelPlacement='start'
            />
    );
} 


// export function Name() {
//     const { recipe_data, setRecipeData } = React.useContext(RecipeContext);

//     return (

//     );
// } 

