import * as React from 'react';
import * as MUI from '@mui/material';

import * as RecipeIO from '../RecipeIO'

export default function RecipeFermentableDetails(props) {
    return (
        <MUI.Box
            {...props}
            component={MUI.Paper}
            sx={{
                minwidth: 300
            }}
        >
            {/* TODO: proper title component? */}
            <MUI.Box m={2}>
                <MUI.Typography variant="h6" m={1}> Fermentable Related Details </MUI.Typography>
            </MUI.Box>

            <MUI.Stack spacing={2} mx={2} my={4}>
                <RecipeIO.QuantityField/>

                <RecipeIO.OriginalGravityField/>

                <RecipeIO.BatchRequiredSugarPerGallon/>

                <RecipeIO.BatchRequiredSugar/>

                <RecipeIO.SRMIndicatorIcon/>
            </MUI.Stack>
        </MUI.Box>
    );
}