import * as React from 'react';
import * as MUI from '@mui/material'
import * as MUICharts from '@mui/x-charts';
import { RecipeContext } from '../../Recipe';


export default function FermentablesChart(props) {
  const { recipe_data } = React.useContext(RecipeContext);

  const [prev_fermentables, setPrevFermentables] = React.useState({});
  const [chart_data, setChartData] = React.useState();

  if (prev_fermentables !== recipe_data.fermentables) {
    RecalculateChart();
    setPrevFermentables(recipe_data.fermentables);
  }

  function RecalculateChart() {
    let data = recipe_data.fermentables.map((fermentable) => {
      return {
        value: Number(fermentable.proportion),
        label: fermentable.fermentable_data.name
      };
    });
    setChartData(data);
  }

  return (
    <MUI.Box component={MUI.Paper}>
      <MUI.Typography variant="h6" sx={{m: 2}}>Fermentables Breakdown</MUI.Typography>

      <MUICharts.PieChart 
        {...props}
        series={[
          {
            data: chart_data,
            arcLabel: (item) => `(${item.value}%)`,
            arcLabelMinAngle: 45,
            innerRadius: 40,
            outerRadius: 140,
            paddingAngle: 3,
            cornerRadius: 4,
            startAngle: 0,
            endAngle: 360,
          },
        ]}
        width={550}
        height={300}

        //TODO: why do I need to set this to stop the legend getting cropped?? 
        margin={{ "right": 200 }} />
    </MUI.Box>
  );
}
