import * as React from 'react';
import * as MUI from '@mui/material';

import FermentablesChart from '../forms/FermentablesChart';
import RecipeGeneralDetails from '../forms/RecipeGeneralDetails'
import GravitySlider from '../forms/GravitySlider'

export default function GeneralTab(props) {
    const {children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        { value === index && (
          <MUI.Stack m={2} spacing={2} direction="row" justifyContent={'center'}> 
              
              <RecipeGeneralDetails/>

              <MUI.Stack m={2} spacing={2} direction="column"> 
                <GravitySlider/>
                
                <FermentablesChart  sx={{
                  minwidth: 300
                }}/> 
              </MUI.Stack>

            </MUI.Stack>
        )}
      </div>
    );
  }