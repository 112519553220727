import * as React from 'react';

import * as BeerData from './BeerData'

export const RecipeContext = React.createContext("");

export function createRecipeFermentable(recipe_data, fermentable_data, proportion, extract_efficiency) {
    let new_fermentable = {fermentable_data, proportion, extract_efficiency};
    //Create field to stop render error, will be updated by effect in FermentablesTable after first render.
    new_fermentable.required_sugar = 0;
    new_fermentable.quantity = 0;
    //The actual colour that this fermentable contributes to the beer.
    new_fermentable.imparted_SRM = 0;
    return(new_fermentable);
}

export function createRecipeHop(hop_data, quantity_per_gal, addition_time) {
    let new_hop = {hop_data, quantity_per_gal, addition_time};
    //Create field to stop render error, will be updated by effect in HopTable after first render.
    new_hop.IBUs = 0;
    return(new_hop);
}

//TODO: just loads a static recipe for now - will eventually load from a DB.
export function LoadRecipe() {
    let recipe = {
        batch_volume: 0.2641720524,
        original_gravity: 1053,
        final_gravity: 1010,
        batch_total_IBUs: 0,    //Calculated after first render.
        metric: false,

        batch_required_sugar_per_gallon() { 
            return this.original_gravity - BeerData.GU_WATER 
        },  
        batch_required_sugar() {
            return (this.batch_required_sugar_per_gallon() * this.batch_volume).toFixed(2)
        },
        //ABV = (OG – FG) * 131.25
        batch_abv() {
            return ((this.original_gravity - this.final_gravity)/1000*131.25).toFixed(2)
        }

    }

    //Need to do this outside of the recipe definition because createRecipeFermenetable uses batch_required_sugar()
    recipe.fermentables = [
        createRecipeFermentable(recipe, BeerData.fermentables[0], 43, 100),
        createRecipeFermentable(recipe, BeerData.fermentables[2], 45, 100),
        createRecipeFermentable(recipe, BeerData.fermentables[5], 16, 100),
        createRecipeFermentable(recipe, BeerData.fermentables[12], 10, 70),
    ]
    
    recipe.hops = [
        createRecipeHop(BeerData.hops[0], 3, 20)
    ]
      
    return recipe  
}