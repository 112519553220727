import * as React from 'react';
import * as MUI from '@mui/material';

import RecipeFermentableDetails from '../forms/RecipeFermentableDetails.js'
import FermentablesTable from "../forms/FermentablesTable.js";

import * as BeerData from '../../BeerData'

function groupFermentablesByType(fermentables) {
  var reducer = function(groups, fermentable) {
    var group = groups[fermentable.type] || (groups[fermentable.type] = []);
    group.push(fermentable.name);
    return groups;
  };
  return fermentables.reduce(reducer, {});
}

//TODO: this will need to be able to be recalcualted when a user adds one (not globally const)
export const type_grouped_fermentables = groupFermentablesByType(BeerData.fermentables);

export default function FermentablesTab(props) {
  const {children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      { value === index && (
        <MUI.Stack m={2} spacing={2} direction="row"> 
            <RecipeFermentableDetails/>
            <FermentablesTable/>
          </MUI.Stack>
      )}
    </div>
  );
}